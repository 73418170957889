import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import React from 'react';

import HomeContainer from '../../containers/Home';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import { changeParticipantsField } from './../../store/actions/participants';

import {
	loginParticipantAction,
	registerParticipantAction,
	getParticipantDataAction,
	registerParticipantPassportAction,
	registerParticipantScanPassportAction,
	registerParticipantBillAction,
	registerParticipantQuestionAction,
} from '../../store/actions/participants';

export default connect(
	(s) => ({
		promotion: s.promotions.state,
		successRegistration: s.participants.successRegistration,
		successLogin: s.participants.successLogin,
		participantId: s.participants.participantId,
		participantToken: s.participants.participantToken,
		participant: s.participants.participant,
		passportUploaded: s.participants.passportUploaded,
		passportScanUploaded: s.participants.passportScanUploaded,
		billUploaded: s.participants.billUploaded,
		questionUploaded: s.participants.questionUploaded,
	}),
	{
		loginParticipantAction,
		registerParticipantAction,
		getParticipantDataAction,
		registerParticipantPassportAction,
		registerParticipantScanPassportAction,
		registerParticipantBillAction,
		registerParticipantQuestionAction,
		changeParticipantsField,
	}
)(
	({
		promotion,
		successRegistration,
		successLogin,
		participantId,
		participantToken,
		participant,
		passportUploaded,
		passportScanUploaded,
		loginParticipantAction,
		registerParticipantAction,
		getParticipantDataAction,
		registerParticipantPassportAction,
		registerParticipantScanPassportAction,
		registerParticipantBillAction,
		registerParticipantQuestionAction,
		billUploaded,
		questionUploaded,
		changeParticipantsField
	}) => {
		const logoutAccountAction = () => {
			console.log('logoutAccountAction')

			changeParticipantsField({ name: 'successRegistration', value: null });
			changeParticipantsField({ name: 'successLogin', value: null });
			changeParticipantsField({ name: 'participantId', value: null });
			changeParticipantsField({ name: 'participantToken', value: null });
			changeParticipantsField({ name: 'participant', value: null });
			changeParticipantsField({ name: 'passportUploaded', value: null });
			changeParticipantsField({ name: 'passportScanUploaded', value: null });
			changeParticipantsField({ name: 'billUploaded', value: null });
			changeParticipantsField({ name: 'questionUploaded', value: null });
		};

		const clearNotifications = () => {
			console.log('clearNotifications')

			changeParticipantsField({ name: 'passportUploaded', value: null });
			changeParticipantsField({ name: 'passportScanUploaded', value: null });
			changeParticipantsField({ name: 'questionUploaded', value: null });
			changeParticipantsField({ name: 'billUploaded', value: null });
		};

		const clearParticipant = () => {
			console.log('clearParticipant')

			changeParticipantsField({ name: 'participant', value: null });
		};

		return (
			<div className={'main-page-wrapper'}>
				{promotion && promotion.name && (
					<Helmet>
						<title>Акция: {promotion.name}</title>
					</Helmet>
				)}

				<Navbar title={promotion && promotion.name ? `Акция: ${promotion.name}` : 'Акция'} />

				{promotion && promotion.texts && (
					<HomeContainer
						promotion={promotion}
						successRegistration={successRegistration}
						successLogin={successLogin}
						participantId={participantId}
						participantToken={participantToken}
						participant={participant}
						passportUploaded={passportUploaded}
						passportScanUploaded={passportScanUploaded}
						loginParticipantAction={loginParticipantAction}
						registerParticipantAction={registerParticipantAction}
						getParticipantDataAction={getParticipantDataAction}
						registerParticipantPassportAction={registerParticipantPassportAction}
						registerParticipantScanPassportAction={registerParticipantScanPassportAction}
						registerParticipantBillAction={registerParticipantBillAction}
						registerParticipantQuestionAction={registerParticipantQuestionAction}
						billUploaded={billUploaded}
						questionUploaded={questionUploaded}
						logoutAccountAction={logoutAccountAction}
						clearNotifications={clearNotifications}
						clearParticipant={clearParticipant}
					/>
				)}

				<Footer />
			</div>
		);
	}
);
