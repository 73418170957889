import { AES } from "crypto-js";

export const dev = false;
export const api_url = dev ? 'http://localhost:8011/' : 'https://promoapi.shw-platform.com/';

export const cryptoKey = 'bVD4X3$P7QDLhmY^jLZZK@sw4rCX8CTS';
export const cryptData = true;

export const tryToEncryptObject = (data) => {
    return cryptData ? {
        data: AES.encrypt(JSON.stringify(data), cryptoKey).toString(),
    } : data
}