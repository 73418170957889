import axios from 'axios';
import { api_url, tryToEncryptObject } from '../../../common/config';

// enable axios default configuration
axios.defaults.withCredentials = true;

// enable axios interceptors -> intercept the global error
axios.interceptors.response.use((response) => {
	return response
}, (error) => {
	const originalRequest = error.config

	console.log({
		error,
		originalRequest
	})

	// if the error is 401 and hasent already been retried
	if (error && error.response && error.response.status === 401) {

		// try to add error to url
		if (!location.href.includes('error=Unauthorized')) {
			if (location.href.indexOf("?") === -1) {
				window.location = location.href += "?error=Unauthorized";
			}
			else {
				window.location = location.href += "&error=Unauthorized";
			}
		}

		return Promise.reject(null)
	}

	return Promise.reject(error)
})


export default class {

	// login participant endpoint
	static loginParticipantEndpoint = (data) => axios.post(`${api_url}landing/login`, tryToEncryptObject(data));

	// login participant endpoint
	static refreshParticipantToken = (token) => axios.post(`${api_url}landing/refreshToken`, tryToEncryptObject({token: token}));

	// register participant endpoint
	static registerParticipantEndpoint = (data) => {
		// calculate user timezone
		data.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
		axios.post(`${api_url}landing/create`, tryToEncryptObject(data));
	}

	// register user passport
	static registerParticipantPassportEndpoint = ({ id, token, passport }) =>
		axios.post(`${api_url}landing/update-passport`, tryToEncryptObject({id, passport}), {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});

	// register user passport scan
	static registerParticipantScanPassportEndpoint = ({ id, token, scan }) =>
		axios.post(`${api_url}landing/upload-scan?id=${id}`, scan, {
			headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
		});

	// get participant data
	static getParticipantDataEndpoint = ({ token, id, promotion, }) =>
		axios.post(`${api_url}landing/participant`, tryToEncryptObject({id, promotion}), {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});

	// register participant code and bill
	static registerParticipantBillEndpointOld = ({ token, promotion, participant, codes, qr  }) =>
		axios.post(`${api_url}landing/bill`, {promotion, participant, codes, qr}, {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});

	// register participant code and bill
	static registerParticipantBillEndpoint = ({ token, promotion, participant, code, qr, photoQr, file  }) =>
		//axios.post(`${api_url}landing/bill-new?promotion=${promotion}&participant=${participant}&code=${code}&qr=${qr}&photoQr=${photoQr}`,
		axios.post(`${api_url}landing/bill-new`,
			file, {
				params: tryToEncryptObject({promotion, participant, code, qr, photoQr}),
				headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
			});

	// register participant question
	static registerParticipantQuestionEndpoint = ({ token, promotion, participant, question }) =>
		axios.post(`${api_url}landing/question`, tryToEncryptObject({promotion, participant, question}), {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});
}

